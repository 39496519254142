import React, { useState, useEffect } from 'react';
import LeadForm from './LeadForm';
import { CreateLead } from '../model/CreateLead';
import { GetLead } from '../model/GetLead';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';

import { message } from 'antd';

const normalizeLeadTimes = (leadTimes, initialValues) => {
   return leadTimes.map((e) => {
      const firstTime = dayjs(e?.timeArr[0]);
      const secondTime = dayjs(e?.timeArr[1]);
      let date = e?.fromTimestamp || initialValues.fromTimestamp;

      const timeObj = {
         fromTimestamp: dayjs(date)
            .set('hour', firstTime.hour())
            .set('minute', firstTime.minute())
            .utc()
            .format(),
         toTimestamp: dayjs(date)
            .set('hour', secondTime.hour())
            .set('minute', secondTime.minute())
            .utc()
            .format()
      };

      // if (e?.id) {
      //    timeObj.id = e.id;
      // }

      return timeObj;
   });
};

const CreateOrEditLead = ({ id = null, callbackOnSuccess = () => {} }) => {
   const isEditForm = !!id;
   const [initialValues, setIntialValues] = useState({});
   const [loadForm, setLoadForm] = useState(false);
   const navigate = useNavigate();

   useEffect(() => {
      if (!isEditForm) {
         return;
      }

      GetLead(id).then((res) => {
         setIntialValues({ ...initialValues, ...res });
         setLoadForm(true);
      });

      return () => {
         setLoadForm(false);
      };
   }, [id]);

   const onSuccess = async (values, setLoading) => {
      setLoading(true);

      if (values?.entityCategories?.length > 0) {
         values.entityCategories = values.entityCategories.map((e) => ({
            materialCategoryId: e.materialCategoryId,
            price: e.price,
            capacity: e.capacity
         }));

         let categoriesIds = values.entityCategories.map(
            (e) => e.materialCategoryId
         );
         if (categoriesIds.length !== [...new Set(categoriesIds)].length) {
            message.error('Вы выбрали одинаковые категории');
            return;
         }
      }

      if (values?.leadTimes?.length > 0) {
         const normalized = normalizeLeadTimes(values?.leadTimes, initialValues);
         values.leadTimes = normalized;
      }

      values.status = {
         status: values.status || 'active',
         comment: values?.statusComment || ''
      };

      values.driverId = null;

      if (values.price || values.price === 0) {
         values.price = parseFloat(values.price).toFixed(1);
      }

      await CreateLead({ leadData: values, images: values?.images || [] })
         .then((res) => {
            callbackOnSuccess();

            message.success(`Заявка ${id} скопирована в заявку ${res.id}`);
            navigate(`/leads/${res.id}`);
            message.success(`Вы перешли в новую заявку ${res.id}`);
         })
         .catch(() => message.error('Заявка не создана, возникла ошибка'))
         .finally(() => setLoading(false));
   };

   if (!loadForm && isEditForm) {
      // Чтобы initialValues прогрузились при первом рендере, если это Редактируемая форма
      return <></>;
   }

   return (
      <>
         <Alert
            message="При копировании заявки, автоматически ставиться статус АКТИВНЫЙ И УБИРАЕТСЯ ВОДИТЕЛЬ!"
            type="warning"
            showIcon
            style={{ marginBottom: '15px' }}
         />
         <LeadForm
            initialValues={initialValues}
            isEditForm={false}
            onSuccess={onSuccess}
         />
      </>
   );
};

export default CreateOrEditLead;
