import React, { useState, useEffect } from 'react';
import { StatusTag } from '@shared/ui';
import {
   Space,
   Spin,
   Button,
   Typography,
   Tag,
   Collapse,
   Divider,
   Alert
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';

import { GetLeadList } from '@features/list-lead/model/GetLeadList';
import { getTimeAgo } from '@shared/utils/timeAgo';
import dayjs from 'dayjs';
import ListLeadsOfRoute from '@features/list-route/ui/ListLeadsOfRoute';
import { getLeadConfirmById } from '@shared/api/all/lead';
import ModalButtonDeal from './ModalButtonDeal';

const getText = (status) => {
   switch (status) {
      case 'confirmed':
         return 'Подтверждено';
      case 'notconfirmed':
         return 'Не подтверждено';
      case null:
         return 'Нет ответа';
      default:
         return 'Пусто';
   }
};

const LeadConfirm = ({ id }) => {
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState([]);

   useEffect(() => {
      fetchData(id);
   }, [id]);

   const fetchData = (id) => {
      setLoading(true);
      getLeadConfirmById(id)
         .then((res) => {
            setData([res.json.data]);
            setLoading(false);
         })
         .catch((err) => {
            console.error(err);
            setLoading(false);
         });
   };

   const closeModal = () => {
      fetchData(id);
   };

   if (loading) {
      return <Spin />;
   }

   if (!data?.[0]?.leadId) {
      return <></>;
   }

   return (
      <Space direction="vertical" style={{ width: '100%', marginBottom: '15px' }}>
         {data.map((item, index) => (
            <>
               <Alert
                  message={`Продавец: ${getText(item.statusSeller)}`}
                  showIcon
                  type={item.statusSeller === 'confirmed' ? 'success' : 'error'}
               />

               <Alert
                  message={`Водитель: ${getText(item.statusDriver)}`}
                  showIcon
                  type={item.statusDriver === 'confirmed' ? 'success' : 'error'}
               />
            </>
         ))}

         <ModalButtonDeal id={id} closeModal={closeModal} />
      </Space>
   );
};

export default LeadConfirm;
