import React from 'react';
import { Tooltip, Typography, Flex, Tag } from 'antd';
import TooltipTextWithEllipsis from '@shared/ui/Tooltips/TooltipTextWithEllipsis';
import CategoryIcon from '@features/manage-materialcategories/ui/CategoryIcon';

const { Text } = Typography;

const RowLeadCategories = ({ data }) => {
   if (data && data?.length === 0) {
      return null;
   }

   return (
      <div className="row-lead-categories">
         {data?.map((item) => (
            <Tag icon={<CategoryIcon icon={item.materialCategory.icon} />}>
               {item.capacity} {'кг'}
            </Tag>
         ))}
      </div>
   );
};

export default RowLeadCategories;
