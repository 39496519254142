import { Drawer, Button } from 'antd';
import { useState, useEffect } from 'react';
import CreateOrEditNote from './CreateOrEditNote';
import Notes from '@features/notes/ui/index';

const ModalListOfNotes = ({
   entityId,
   entityType,
   closeModal,
   hideTitle = false
}) => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   useEffect(() => {
      setIsModalOpen(!!entityId);
   }, [entityId]);

   const handleOk = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const isHaveData = !!entityId;

   return (
      <Drawer
         open={isModalOpen}
         onOk={handleOk}
         onCancel={handleCancel}
         onClose={handleCancel}
         title={`Список заметок`}
         footer={null}
         width={500}
         destroyOnClose={true}>
         {isHaveData ? (
            <Notes
               entityId={entityId}
               entityType={entityType}
               hideTitle={hideTitle}
            />
         ) : null}
      </Drawer>
   );
};

export default ModalListOfNotes;
