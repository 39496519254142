import React, { useState } from 'react';
import { Tooltip, Table, Typography, Space } from 'antd';
import TooltipTextWithEllipsis from '@shared/ui/Tooltips/TooltipTextWithEllipsis';
import {
   timestampToNormalTime,
   timestampToNormalDDMMYY,
   timestampToNormalDDMMYYWithDay
} from '@shared/utils/tsToTime';
import {
   EditOutlined,
   StopOutlined,
   ClockCircleOutlined,
   ArrowRightOutlined,
   DashOutlined,
   CheckOutlined
} from '@ant-design/icons';

const { Text, Link } = Typography;

const LeadTimesList = ({ data, showAllEnabled = false }) => {
   const [showAll, setShowAll] = useState(false);

   if (data && data?.length === 0) {
      return null;
   }

   let newData = data;
   if (data?.length >= 3) {
      newData = showAll ? data : data.slice(0, 2);
   }

   if (showAllEnabled) {
      newData = data;
   }

   return (
      <>
         {data?.length > 0 && (
            <Space size="small" direction="vertical" className="lead-times">
               {newData.map((item) => (
                  <Space size="small" key={`leadtimes-${item.id}-${item.createdAt}`}>
                     <span
                        style={{
                           'width': '80px',
                           'display': 'inline-block',
                           'text-align': 'right'
                        }}>
                        {timestampToNormalDDMMYYWithDay(item.fromTimestamp)}
                     </span>
                     {item?.isChoosed ? (
                        <Tooltip title="Выбрано для вывоза">
                           <CheckOutlined style={{ color: 'green' }} />
                        </Tooltip>
                     ) : (
                        <ClockCircleOutlined style={{ color: '#00000073' }} />
                     )}

                     <Text type="secondary" style={{ fontSize: '14px' }}>
                        {`с ${timestampToNormalTime(
                           item.fromTimestamp
                        )} по ${timestampToNormalTime(item.toTimestamp)}`}
                     </Text>
                  </Space>
               ))}

               {data.length > 2 && !showAllEnabled ? (
                  <div>
                     <Link
                        type="link"
                        onClick={() => setShowAll((prev) => !prev)}
                        style={{
                           fontSize: '11px',
                           position: 'absolute',
                           bottom: '1px'
                        }}>
                        {showAll ? 'Скрыть' : 'Развернуть'}
                     </Link>
                  </div>
               ) : null}
            </Space>
         )}
      </>
   );
};

export default LeadTimesList;
