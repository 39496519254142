import { Drawer, Button } from 'antd';
import { useState, useEffect } from 'react';
import CreateCopyLead from './CreateCopyLead';

const ModalLeadCopyForm = ({ selectedLead, closeModal }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   useEffect(() => {
      setIsModalOpen(!!selectedLead?.id);
   }, [selectedLead]);

   const handleOk = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const isHaveData = !!selectedLead?.id;

   return (
      <Drawer
         title={`Копировать заявку №${selectedLead?.id}`}
         open={isModalOpen}
         onOk={handleOk}
         onCancel={handleCancel}
         onClose={handleCancel}
         footer={null}
         width={800}
         destroyOnClose={true}
         extra={<Button onClick={handleCancel}>Закрыть</Button>}>
         {isHaveData && (
            <CreateCopyLead id={selectedLead?.id} callbackOnSuccess={handleCancel} />
         )}
      </Drawer>
   );
};

export default ModalLeadCopyForm;
