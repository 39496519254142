/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { useSelector } from 'react-redux';
import { getUserAuthData } from '@entitles/User';
import { Content, Title, VerticalSpace } from '@shared/ui';

import { Card, Col, Row, Flex } from 'antd';
import ServiceCountCard from '@widgets/StatsCards/ServiceCountCard';
import ServicesGraphs from '@widgets/StatsCards/ServicesGraphs';
import RoutesCommissionCard from '@widgets/StatsCards/RoutesCommissionCard';

import InWorkCars from '@widgets/StatsCards/InWorkCars';
import FreeCars from '@widgets/StatsCards/FreeCars';
import LigaPMScript from '@widgets/StatsCards/LigaPMScript';

import LeadsCapacitiesCard from '@widgets/StatsCards/LeadsCapacitiesCard';

import {
   CarOutlined,
   SendOutlined,
   AppstoreOutlined,
   ShareAltOutlined,
   UsergroupAddOutlined
} from '@ant-design/icons';

const listCountStats = [
   // {
   //    name: 'user',
   //    title: 'Пользователей',
   //    icon: <UsergroupAddOutlined />
   // },
   {
      name: 'lead',
      title: 'Заявок',
      icon: <AppstoreOutlined />,
      col: 10,
      minWidth: '540px',
      urlMain: '/leads'
   },
   {
      name: 'seller',
      title: 'Продавцов',
      icon: <SendOutlined />,
      col: 4,
      minWidth: '260px',
      urlMain: '/sellers'
   },
   {
      name: 'driver',
      title: 'Водителей',
      icon: <CarOutlined />,
      col: 4,
      minWidth: '260px',
      urlMain: '/drivers'
   }
   // {
   //    name: 'route',
   //    title: 'Маршрутов',
   //    icon: <ShareAltOutlined />
   // }
];

const WelcomePage = () => {
   const userInfo = useSelector(getUserAuthData);

   return (
      <Content>
         <Title
            documentTitle={`Добро пожаловать ${userInfo?.firstName || ''} ${
               userInfo?.lastName || ''
            }`}>
            Добро пожаловать {userInfo?.firstName || ''} {userInfo?.lastName || ''}
         </Title>

         <VerticalSpace />

         <Row gutter={[16, 24]}>
            {listCountStats.map((item) => (
               <Col
                  style={{ minWidth: `${item.minWidth}` }}
                  span={item.col}
                  key={item.title}
                  className="stats-card-count-col">
                  <ServiceCountCard
                     icon={item.icon}
                     serviceName={item.name}
                     serviceTitle={item.title}
                     urlMain={item.urlMain}
                  />
               </Col>
            ))}
            {/* <Col
               className="gutter-row"
               span={4}
               key={`inwork`}
               className="stats-card-count-col">
               <Card bordered={false}>
                  <InWorkCars />
               </Card>
            </Col> */}
         </Row>

         <Row gutter={[16, 24]} style={{ marginTop: '20px' }}>
            <Col className="gutter-row" span={12} className="stats-card-count-col">
               <Card
                  bordered={false}
                  title={
                     <div className="stats-title-card">
                        Ценовой индекс на основные виды ЦБП РФ
                     </div>
                  }>
                  <LigaPMScript />
               </Card>
            </Col>

            <Col className="gutter-row" span={12} className="stats-card-count-col">
               <Card
                  bordered={false}
                  title={<div className="stats-title-card">Динамика изменения</div>}>
                  <ServicesGraphs />
               </Card>

               {/* <Row gutter={[16, 24]} style={{ marginTop: '20px' }}>
                  <Col
                     className="gutter-row"
                     span={8}
                     className="stats-card-count-col">
                     <Card bordered={false}>
                        <RoutesCommissionCard />
                     </Card>
                  </Col>
                  <Col
                     className="gutter-row"
                     span={8}
                     className="stats-card-count-col">
                     <Card bordered={false}>
                        <LeadsCapacitiesCard />
                     </Card>
                  </Col>

                  <Col
                     className="gutter-row"
                     span={8}
                     className="stats-card-count-col">
                     <Card bordered={false}>
                        <FreeCars />
                     </Card>
                  </Col>
               </Row> */}
            </Col>
         </Row>

         {/* <Row gutter={16}>
            <Col span={4}>
               <Statistic title="Active Users" value={112893} />
            </Col>
            <Col span={4}>
               <Statistic
                  title="Account Balance (CNY)"
                  value={112893}
                  precision={2}
               />
            </Col>
            <Col span={4}>
               <Statistic
                  title="Account Balance (CNY)"
                  value={112893}
                  precision={2}
               />
            </Col>
            <Col span={4}>
               <Statistic
                  title="Account Balance (CNY)"
                  value={112893}
                  precision={2}
               />
            </Col>
            <Col span={4}>
               <Statistic
                  title="Account Balance (CNY)"
                  value={112893}
                  precision={2}
               />
            </Col>
            <Col span={4}>
               <Statistic
                  title="Account Balance (CNY)"
                  value={112893}
                  precision={2}
               />
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span={4}>
               <Card bordered={false}>
                  <Statistic
                     title="Водителей"
                     value={11.28}
                     precision={2}
                     valueStyle={{ color: "#3f8600" }}
                     prefix={<ArrowUpOutlined />}
                     suffix="%"
                  />
               </Card>
            </Col>
            <Col span={4}>
               <Card bordered={false}>
                  <Statistic
                     title="Продавцов"
                     value={9.3}
                     precision={2}
                     valueStyle={{ color: "#cf1322" }}
                     prefix={<ArrowDownOutlined />}
                     suffix="%"
                  />
               </Card>
            </Col>{" "}
            <Col span={4}>
               <Card bordered={false}>
                  <Statistic
                     title="Заявок"
                     value={9.3}
                     precision={2}
                     valueStyle={{ color: "#cf1322" }}
                     prefix={<ArrowDownOutlined />}
                     suffix="%"
                  />
               </Card>
            </Col>{" "}
            <Col span={4}>
               <Card bordered={false}>
                  <Statistic
                     title="Маршрутов"
                     value={9.3}
                     precision={2}
                     valueStyle={{ color: "#cf1322" }}
                     prefix={<ArrowDownOutlined />}
                     suffix="%"
                  />
               </Card>
            </Col>{" "}
            <Col span={4}>
               <Card bordered={false}>
                  <Statistic
                     title="Приемок"
                     value={9.3}
                     precision={2}
                     valueStyle={{ color: "#cf1322" }}
                     prefix={<ArrowDownOutlined />}
                     suffix="%"
                  />
               </Card>
            </Col>{" "}
            <Col span={4}>
               <Card bordered={false}>
                  <Statistic
                     title="Торгов"
                     value={9.3}
                     precision={2}
                     valueStyle={{ color: "#cf1322" }}
                     prefix={<ArrowDownOutlined />}
                     suffix="%"
                  />
               </Card>
            </Col>
         </Row> */}
      </Content>
   );
};

export default WelcomePage;
