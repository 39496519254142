import React, { useEffect, useState } from 'react';
import { Spin, Divider, Button, Col, Rate, Row } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import ModalRatingForm from './ModalRatingForm';
import { GetRatingSimpleList } from '@features/list-rating/model/GetRatingSimpleList';
import ModalButtonRatingCreateToEntity from './ModalButtonRatingCreateToEntity';

import { StatusTag } from '@shared/ui';

const RatingOfEntity = (props) => {
   const {
      actionForEntityType,
      actionForEntityId,
      fromEntityType,
      fromEntityId,
      toEntityType,
      toEntityId,
      status,
      categoryOfRating
   } = props;

   const [data, setData] = useState([]);
   const [loading, setLoading] = useState(false);
   const [modalShow, setModalShow] = useState(false);

   useEffect(() => {
      fetchData(actionForEntityId);
   }, [actionForEntityId]);

   const fetchData = (actionForEntityId) => {
      if (!actionForEntityId) {
         return;
      }

      setLoading(true);

      GetRatingSimpleList({
         actionForEntityType,
         actionForEntityId,
         fromEntityType,
         fromEntityId,
         toEntityType,
         toEntityId,
         categoryOfRating
      }).then((res) => {
         setData(res?.data || []);

         setLoading(false);
      });
   };

   if (loading) {
      return (
         <div>
            <Spin />
         </div>
      );
   }

   const closeModal = () => {
      fetchData(actionForEntityId);
      setModalShow(false);
   };

   if (data.length === 0) {
      return <ModalButtonRatingCreateToEntity {...props} closeModal={closeModal} />;
   }

   if (!actionForEntityId) {
      return <div>Оценка не загружена</div>;
   }

   if (data && data?.length === 0) {
      return <div>Не выставлена оценка</div>;
   }

   return (
      <>
         {modalShow ? (
            <ModalRatingForm selectedRating={data[0]} closeModal={closeModal} />
         ) : null}

         {data.map((item, index) => (
            <Row
               gutter={24}
               key={`rating-${index}`}
               className={
                  item?.status === 'active'
                     ? 'user-card-by-id rate-enabled'
                     : 'user-card-by-id rate-disabled'
               }
               style={{ padding: '20px 10px', maxWidth: '100%' }}>
               <Col span={24}>
                  <Rate
                     className="rate-big"
                     allowHalf
                     disabled={true}
                     allowClear={true}
                     defaultValue={0}
                     value={item?.value / 10}
                  />
               </Col>

               <Divider style={{ padding: 0, margin: 0 }} />

               <Col span={24} style={{ marginTop: '5px' }}>
                  {item?.comment || 'Без комментариев'}
               </Col>

               <Button
                  onClick={() => setModalShow(true)}
                  style={{ marginTop: '10px' }}
                  icon={<EditOutlined />}
                  type="primary">
                  Редактировать
               </Button>
               <div>
                  <StatusTag status={item?.status} type="rating" />
               </div>
            </Row>
         ))}
      </>
   );
};

export default RatingOfEntity;
