import { Tag } from 'antd';
import {
   statusesOfLeads,
   statusesOfSellers,
   statusesOfDrivers,
   statusesOfRoutes,
   statusesOfRatings
} from '@shared/const/statuses';

const StatusTag = ({ status, type }) => {
   const types = {
      lead: statusesOfLeads,
      seller: statusesOfSellers,
      route: statusesOfRoutes,
      driver: statusesOfDrivers,
      rating: statusesOfRatings
   };

   const currentStatuses = types[type];

   return (
      <Tag
         className="status-tag"
         color={currentStatuses[status]?.color}
         icon={currentStatuses[status]?.icon ? currentStatuses[status]?.icon : null}>
         {currentStatuses[status]?.label}
      </Tag>
   );
};

export default StatusTag;
