import React, { useEffect, useState } from 'react';
import { Button, List, Badge, Space, Tooltip, Empty } from 'antd';
import { GetLeadList } from '@features/list-lead/model/GetLeadList';
import { ClockCircleOutlined } from '@ant-design/icons';
import {
   ReloadOutlined,
   EditOutlined,
   EnvironmentOutlined,
   DownloadOutlined
} from '@ant-design/icons';
import EnterToEntityButton from '@widgets/Button/EnterToEntityButton';
import { useNavigate } from 'react-router-dom';
import { Table, Tag, Typography } from 'antd';
import { unitSettings } from '@shared/const/units';
import TooltipCustom from '@shared/ui/Tooltips/TooltipCustom';
import TableLeadCategories from '@features/list-lead/ui/TableLeadCategories';

import { StatusProgress } from '@shared/ui';
const { Text } = Typography;

const suffix = (unit) => unitSettings.find((e) => e?.value === unit)?.shortLabel;

const IconText = ({ icon, text }) => (
   <Space>
      {React.createElement(icon)}
      {text}
   </Space>
);

const SellerLeads = ({ id }) => {
   const [data, setData] = useState([]);
   const [count, setCount] = useState(0);
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate();
   const [pagination, setPagination] = useState({
      page: 0,
      sorter: {
         sort: 'id',
         order: 'desc'
      }
   });

   const fetchData = () => {
      if (loading) {
         return;
      }

      let page = pagination.page + 1;

      setLoading(true);
      GetLeadList({
         sellerId: id,
         page,
         limit: 2,
         ...pagination.sorter
      })
         .then((res) => {
            setData((prev) => [...prev, ...res.data]);
            setCount(res.count);
            setLoading(false);
            setPagination({ ...pagination, page });
         })
         .catch(() => setLoading(false));
   };

   const TitleOfLeads = ({ id, status }) => {
      return (
         <>
            <span
               className="green-span-url"
               style={{ padding: '5px 0' }}
               onClick={() => navigate(`/leads/${id}`)}>
               {`Заявка №${id}`}
            </span>
            <StatusProgress
               status={status}
               type="lead"
               isShowProgress={false}
               showInfo={false}
            />
         </>
      );
   };

   useEffect(() => {
      fetchData();
   }, [id]);

   if (!data?.length) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
   }

   return (
      <>
         <List
            dataSource={data}
            renderItem={(item) => (
               <List.Item
                  key={`item-${item.id}`}
                  actions={
                     [
                        // <Space direction="vertical" key="dsfsdfdsf">
                        //    <Tooltip
                        //       placement="right"
                        //       title={'Редактировать заявку'}
                        //       arrow={false}>
                        //       <Button
                        //          type="primary"
                        //          onClick={() =>
                        //             alert(
                        //                'в разработке, делайте пока через редактирование заявки'
                        //             )
                        //          }
                        //          icon={<EditOutlined />}
                        //       />
                        //    </Tooltip>
                        // </Space>
                     ]
                  }>
                  <List.Item.Meta
                     title={
                        <Space direction="vertical">
                           <Space size="small">
                              {item?.entityCategories?.length > 0 ? (
                                 <TooltipCustom
                                    key={item.id}
                                    text={
                                       <TableLeadCategories
                                          data={item?.entityCategories}
                                       />
                                    }
                                    title={null}>
                                    <span>
                                       <TitleOfLeads
                                          id={item.id}
                                          status={item.status}
                                       />
                                    </span>
                                 </TooltipCustom>
                              ) : (
                                 <>
                                    <TitleOfLeads
                                       id={item.id}
                                       status={item.status}
                                    />
                                 </>
                              )}
                           </Space>

                           {/* <Tag bordered={false}>
                              <Text type="secondary" style={{ fontSize: '12px' }}>
                                 {item.materialCategory.name}:{' '}
                                 {item?.capacity
                                    ? `${item.capacity} ${suffix(
                                         item.materialCategory.unit
                                      )}`
                                    : `Не указан вес`}
                              </Text>
                           </Tag> */}
                        </Space>
                     }
                  />
               </List.Item>
            )}
         />

         <Space
            direction="horizontal"
            style={{ width: '100%', justifyContent: 'center' }}>
            <Button
               type="primary"
               loading={loading}
               icon={<DownloadOutlined />}
               onClick={() => fetchData()}
               disabled={count === data.length}>
               Загрузить больше
            </Button>
         </Space>
      </>
   );
};

export default SellerLeads;
