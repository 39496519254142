function getEntityName(e) {
   return `${e?.lastName || ''} ${e?.firstName || ''}`;
}

function getEntityNameWithBR(e) {
   return (
      <>
         {e?.lastName || ''} <br /> {e?.firstName || ''}
      </>
   );
}

function formatPhoneNumber(number) {
   const strNumber = number.toString();

   if (strNumber.length !== 10) {
      return number;
   }

   const countryCode = '+7';
   const areaCode = strNumber.slice(0, 3);
   const firstPart = strNumber.slice(3, 6);
   const secondPart = strNumber.slice(6, 8);
   const thirdPart = strNumber.slice(8, 10);

   const formattedNumber = `${countryCode} ${areaCode} ${firstPart}-${secondPart}-${thirdPart}`;

   return formattedNumber;
}

export { getEntityName, getEntityNameWithBR, formatPhoneNumber };
