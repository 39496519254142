import React from 'react';
import { Descriptions, Tag } from 'antd';
import {
   timestampToNormalTime,
   timestampToNormalDDMMYY
} from '@shared/utils/tsToTime';
import { useNavigate } from 'react-router-dom';
import { formatPhoneNumber } from '@shared/utils/entity';
import { Title, StatusTag, DateWithAgo } from '@shared/ui';
import { StarFilled } from '@ant-design/icons';
import { statusesOfSellers } from '@shared/const/statuses';

const SellerSimpleDescriptions = ({ data }) => {
   const navigate = useNavigate();

   const sellerLittleDescription = [
      {
         label: 'ID',
         key: 'id',
         render: (_, record) => (
            <>
               {_}

               <Tag
                  color={statusesOfSellers[record.status]?.color}
                  style={{ marginLeft: '10px' }}>
                  {statusesOfSellers[record.status]?.label}
               </Tag>

               <span
                  className="green-span-url"
                  style={{}}
                  onClick={() => navigate(`/sellers/${_}`)}>
                  Перейти
               </span>
            </>
         )
      },
      {
         label: 'ФИО',
         key: 'firstName',
         render: (_, record) =>
            `${record.lastName} ${record.firstName} ${record?.surName || ''}`
      },
      {
         label: 'Номер телефона',
         key: 'mobileNumber',
         render: (_) => formatPhoneNumber(_)
      },
      {
         label: 'Почта',
         key: 'email',
         render: (_) => _ || 'Не задано'
      },
      // {
      //    label: 'Рейтинг',
      //    key: 'rating',
      //    render: (_) => (
      //       <Tag bordered={false}>
      //          <StarFilled style={{ color: '#ffca00' }} /> {_ ? _ / 10 : 0}
      //       </Tag>
      //    )
      // },
      {
         label: 'Дата регистрации',
         key: 'createdAt',
         render: (_) => `${timestampToNormalDDMMYY(_)} в ${timestampToNormalTime(_)}`
      },
      {
         label: 'Учредитель',
         key: 'organization',
         render: (_) => (_ ? `Юридическое лицо ${_}` : 'Физическое лицо')
      },
      {
         label: 'Последний вход',
         key: 'lastSeenAt',
         render: (_) => <DateWithAgo date={_} showDate={false} />
      }
   ];

   return (
      <Descriptions size="small">
         {sellerLittleDescription.map((e) => (
            <Descriptions.Item
               key={`${data.id}-${e.label}`}
               label={e.label}
               span={3}>
               {e.render(data[e.key], data)}
            </Descriptions.Item>
         ))}
      </Descriptions>
   );
};

export default SellerSimpleDescriptions;
