import React from 'react';
import { Tooltip } from 'antd';

const TooltipCustom = ({
   text,
   children,
   placement = 'bottomLeft',
   title = null,
   overlayClassName = ''
}) => {
   return (
      <Tooltip
         placement={placement}
         title={
            <>
               {title ? <div className="tooltip-custom-title">{title}</div> : null}

               {text}
            </>
         }
         overlayClassName={`tooltip-white ${overlayClassName}`}>
         {children}
      </Tooltip>
   );
};

export default TooltipCustom;
