import React from 'react';
import { Tooltip, Table, Typography } from 'antd';
import TooltipTextWithEllipsis from '@shared/ui/Tooltips/TooltipTextWithEllipsis';

const { Text } = Typography;

const CategoryIcon = ({ icon }) => {
   if (icon?.length === 0) {
      return <div className="category-icon" />;
   }

   return (
      <div
         className="category-icon"
         style={{
            backgroundImage: `url(${icon})`
         }}
      />
   );
};

export default CategoryIcon;
