import React from 'react';
import { Tooltip } from 'antd';
import { addElipsisToText } from '@shared/utils/default';

const TooltipTextWithEllipsis = ({
   text,
   maxLength,
   className = 'tooltip-ellipsis-text',
   mainText = ''
}) => {
   if (!text) {
      return '';
   }

   if (text.length <= maxLength) {
      return text;
   }

   return (
      <Tooltip placement="topRight" title={text} overlayClassName="tooltip-custom">
         <span className={className}>
            {mainText ? mainText : <>{addElipsisToText(text, maxLength)}</>}
         </span>
      </Tooltip>
   );
};

export default TooltipTextWithEllipsis;
