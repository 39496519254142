/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select } from '@shared/ui';
import SelectAddress from '@widgets/FormItems/SelectAddress';
import SelectCategoriesOfEntity from '@widgets/FormItems/SelectCategoriesOfEntity';
import { Col, Row, Alert, Radio, Typography, Checkbox } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { russianOneWordRequired } from '@shared/config/fieldValidatorSettings';
import { statusesOfSellers } from '@shared/const/statuses';
import InputUniqueNumberOfEntity from '@widgets/FormItems/InputUniqueNumberOfEntity';
import { addressExtraFields } from '@shared/const/leadExtraFields';
import { getTypeOfAddresses } from '@shared/api/all/const';
const { TextArea } = Input;
const { Text } = Typography;

const SellerForm = (props) => {
   const [isLoading, setIsLoading] = useState(false);
   const [isDisabledButton, setIsDisabledButton] = useState(true);
   const [isEnabledRadioLegal, setIsEnabledRadioLegal] = useState(false);
   const [typeOfAddresses, setTypeOfAddresses] = useState([]);
   const { initialValues, onSuccess, isEditForm, errorMessage } = props;
   const [form] = Form.useForm();

   useEffect(() => {
      fetchTypeOfAddresses();
   }, []);

   const fetchTypeOfAddresses = async () => {
      getTypeOfAddresses().then((res) => {
         setTypeOfAddresses(res?.json?.data || []);
      });
   };

   useEffect(() => {
      const org = form.getFieldValue('organization');

      if (org) {
         setIsEnabledRadioLegal(true);
      } else {
         setIsEnabledRadioLegal(false);
      }
   }, [form]);

   const onFinish = (values) => {
      if (!isEnabledRadioLegal) {
         values.organization = '';
         values.surName = '';
      }

      onSuccess(values, setIsLoading).then(() => {
         if (isEditForm) {
            return;
         }

         // form.resetFields();
      });
   };

   const onFinishFailed = (errorInfo) => {
      setIsLoading(false);
      console.log('Failed:', errorInfo);
   };

   const currentStatus = initialValues?.status || 'active';

   const isDisabledInput = (inputName) => {
      const list = {
         firstName: ['active'],
         lastName: ['active'],
         addButton: ['active'],
         deleteButton: ['active'],
         comment: ['active'],
         address: ['active'],
         status: ['active', 'blocked'],
         entityCategories: ['active'],
         email: ['active'],
         uchreditel: ['active'],
         mobileNumber: ['active'],
         organization: ['active'],
         surName: ['active'],
         notes: ['pending', 'active', 'blocked', 'inwork']
      };

      return !list[inputName].includes(currentStatus);
   };

   const statusesCanBeDoOnCurrent = {
      active: ['active', 'blocked'],
      blocked: ['active', 'blocked'],
      deactivated: ['deactivated']
   };

   const isSellerBlocked = currentStatus === 'blocked';

   const actualStatuses = initialValues?.status
      ? statusesCanBeDoOnCurrent[currentStatus].map((e) => statusesOfSellers[e])
      : [statusesOfSellers.active];

   return (
      <Form
         name="basic"
         style={{
            minWidth: 320
         }}
         form={form}
         initialValues={initialValues}
         onFinish={onFinish}
         onFinishFailed={onFinishFailed}
         onValuesChange={() => setIsDisabledButton(false)}
         hideRequiredMark
         layout="vertical">
         <Row gutter={16}>
            <Col span={12}>
               <Form.Item
                  name="firstName"
                  label="Имя"
                  // rules={!isDisabledInput('firstName') && russianOneWordRequired()}
               >
                  <Input placeholder="Имя" disabled={isDisabledInput('firstName')} />
               </Form.Item>
            </Col>
            <Col span={12}>
               <Form.Item
                  name="lastName"
                  label="Фамилия"
                  // rules={!isDisabledInput('lastName') && russianOneWordRequired()}
               >
                  <Input
                     placeholder="Фамилия"
                     disabled={isDisabledInput('lastName')}
                  />
               </Form.Item>
            </Col>
         </Row>

         <Row gutter={16}>
            <Col span={12}>
               <InputUniqueNumberOfEntity
                  disabled={isDisabledInput('mobileNumber')}
                  entityType="seller"
               />
            </Col>
            <Col span={12}>
               <Form.Item
                  name="email"
                  label="E-mail"
                  // rules={
                  //    !isDisabledInput('email') && [
                  //       {
                  //          type: 'email',
                  //          message: 'Не похоже на почту!'
                  //       }
                  //    ]
                  // }
               >
                  <Input placeholder="Почта" disabled={isDisabledInput('email')} />
               </Form.Item>
            </Col>
         </Row>

         <Row gutter={16}>
            <Col span={12}>
               <Form.Item
                  label="Категории сырья"
                  name="entityCategories"
                  // rules={
                  //    !isDisabledInput('entityCategories') && [
                  //       {
                  //          required: true,
                  //          message: 'Выберите категории'
                  //       }
                  //    ]
                  // }
               >
                  <SelectCategoriesOfEntity
                     disabled={isDisabledInput('entityCategories')}
                     initialCategories={initialValues?.entityCategories || null}
                     setField={(value) => {
                        form.setFieldValue('entityCategories', value);
                        setIsDisabledButton(false);
                     }}
                  />
               </Form.Item>{' '}
               <Form.Item
                  name="status"
                  label="Статус"
                  // rules={
                  //    !isDisabledInput('status') && [
                  //       {
                  //          required: true,
                  //          message: 'Выберите статус'
                  //       }
                  //    ]
                  // }
                  defaultValue={statusesOfSellers.active}>
                  <Select
                     style={{
                        width: '100%'
                     }}
                     placeholder="Выберите статус"
                     disabled={isDisabledInput('status')}
                     rules={[
                        {
                           required: true,
                           message: 'Поле не может быть пустым '
                        }
                     ]}
                     options={actualStatuses}
                  />
               </Form.Item>
               <Form.Item label="Учредитель">
                  <Radio.Group
                     disabled={isDisabledInput('uchreditel')}
                     onChange={() => setIsDisabledButton(false)}
                     defaultValue={isEnabledRadioLegal ? 'legal' : 'phy'}
                     buttonStyle="solid"
                     value={isEnabledRadioLegal ? 'legal' : 'phy'}>
                     <Radio.Button
                        value="phy"
                        onClick={() => setIsEnabledRadioLegal(false)}>
                        Физическое
                     </Radio.Button>
                     <Radio.Button
                        value="legal"
                        onClick={() => setIsEnabledRadioLegal(true)}>
                        Юридическое
                     </Radio.Button>
                  </Radio.Group>
               </Form.Item>
            </Col>
            <Col span={12}>
               <Form.Item
                  name="notes"
                  label={
                     <span>
                        Комментарий продавцу{' '}
                        <Text
                           type="secondary"
                           style={{ fontSize: '12px', marginLeft: '5px' }}>
                           видно только вам
                        </Text>
                     </span>
                  }>
                  <TextArea
                     disabled={isDisabledInput('notes')}
                     rows={8}
                     placeholder="Максимум 800 символов"
                     maxLength={800}
                  />
               </Form.Item>
            </Col>
         </Row>

         <Row gutter={24}>
            {isEnabledRadioLegal && (
               <>
                  <Col span={24}>
                     <Form.Item
                        name="organization"
                        label="Организация"
                        rules={
                           !isDisabledInput('organization')
                              ? [
                                   {
                                      required: true,
                                      message: 'Укажите организацию'
                                   }
                                ]
                              : []
                        }>
                        <Input
                           placeholder="Введите организацию"
                           disabled={isDisabledInput('organization')}
                        />
                     </Form.Item>
                  </Col>

                  <Col span={24}>
                     <Form.Item
                        name="surName"
                        label="Отчество"
                        rules={
                           !isDisabledInput('surName')
                              ? [
                                   {
                                      required: true,
                                      message: 'Укажите отчество'
                                   }
                                ]
                              : []
                        }>
                        <Input
                           placeholder="Введите отчество"
                           disabled={isDisabledInput('surName')}
                        />
                     </Form.Item>
                  </Col>
               </>
            )}
         </Row>

         <Row gutter={16}>
            <Col span={24}>
               <Form.Item label="Список адресов">
                  <Form.List name="addresses">
                     {(fields, { add, remove }) => (
                        <>
                           {fields.map(({ key, name, ...restField }) => (
                              <Row
                                 gutter={24}
                                 key={`addresses${key}`}
                                 className="address-background">
                                 <Col span={24}>
                                    {initialValues?.addresses?.[key]?.address ===
                                    'Неоднозначный адрес' ? (
                                       <Form.Item
                                          {...restField}
                                          name={[name, 'address']}
                                          value="Неоднозначный адрес в ДАДАТА"
                                          label="Адрес">
                                          <Input
                                             placeholder="Напишите адрес"
                                             disabled={true}
                                             defaultValue="Неоднозначный адрес в ДАДАТА"
                                             value="Неоднозначный адрес в ДАДАТА"
                                          />
                                       </Form.Item>
                                    ) : (
                                       <Form.Item
                                          {...restField}
                                          label="Адрес"
                                          name={[name, 'address']}
                                          rules={
                                             !isDisabledInput('address')
                                                ? [
                                                     {
                                                        required: true,
                                                        message: 'Укажите адрес'
                                                     }
                                                  ]
                                                : []
                                          }>
                                          <SelectAddress {...restField} />
                                       </Form.Item>
                                    )}
                                 </Col>
                                 <Col span={24}>
                                    <Form.Item
                                       {...restField}
                                       name={[name, 'custom']}
                                       label="Уточнение адреса">
                                       <Input placeholder="Точный адрес" />
                                    </Form.Item>

                                    <Alert
                                       message="Если дадата определила неправильно адрес, укажите точный адрес в поле выше, он не поменяет координаты указанные продавцом, но будет использоваться поверх адреса найденного в дадата"
                                       type="info"
                                       showIcon
                                       style={{ marginBottom: '20px' }}
                                    />
                                 </Col>

                                 <Col span={24}>
                                    <Form.Item
                                       {...restField}
                                       name={[name, 'typeOfAddress']}
                                       label="Место вывоза"
                                       rules={
                                          !isDisabledInput('address')
                                             ? [
                                                  {
                                                     required: true,
                                                     message: 'Укажите место вывоза'
                                                  }
                                               ]
                                             : []
                                       }>
                                       <Select
                                          style={{
                                             width: '100%'
                                          }}
                                          maxCount={1}
                                          placeholder="Выберите место вывоза"
                                          options={typeOfAddresses.map((e) => ({
                                             value: e.value,
                                             label: e.title
                                          }))}
                                       />
                                    </Form.Item>
                                 </Col>

                                 {initialValues?.addresses?.[key]?.address ? (
                                    <Col span={24}>
                                       <Form.Item
                                          {...restField}
                                          name={[name, 'districtName']}
                                          label="Район">
                                          <Input placeholder="Напишите район" />
                                       </Form.Item>
                                    </Col>
                                 ) : null}

                                 {initialValues?.addresses?.[key]?.address ? (
                                    <Col span={24}>
                                       <Form.Item
                                          {...restField}
                                          name={[name, 'cityName']}
                                          label="Город">
                                          <Input placeholder="Напишите город" />
                                       </Form.Item>
                                    </Col>
                                 ) : null}

                                 {initialValues?.addresses?.[key]?.address ? (
                                    <Col span={24}>
                                       <Form.Item
                                          {...restField}
                                          name={[name, 'street']}
                                          label="Улица (без указания номера дома)">
                                          <Input placeholder="Напишите адрес" />
                                       </Form.Item>
                                    </Col>
                                 ) : null}

                                 <Col span={24}>
                                    <Form.Item
                                       {...restField}
                                       name={[name, 'comment']}
                                       label="Комментарий">
                                       <TextArea
                                          disabled={isDisabledInput('comment')}
                                          rows={2}
                                          placeholder="Максимум 200 символов"
                                          maxLength={200}
                                       />
                                    </Form.Item>
                                 </Col>

                                 <Col span={24}>
                                    {addressExtraFields.map((e) => (
                                       <Form.Item
                                          {...restField}
                                          className={
                                             e.type === 'integer' ? '' : 'hide-label'
                                          }
                                          layout="horizontal"
                                          label={e.label}
                                          name={[name, e.value]}
                                          valuePropName={
                                             e.type === 'integer'
                                                ? 'value'
                                                : 'checked'
                                          }
                                          key={e.value + e.label + 'form'}>
                                          {e.type === 'boolean' ? (
                                             <Checkbox>{e.label}</Checkbox>
                                          ) : null}

                                          {e.type === 'integer' ? (
                                             <Input
                                                style={{
                                                   width: '100px',
                                                   display: 'inline-block'
                                                }}
                                                placeholder="Введите значение"
                                                type="number"
                                             />
                                          ) : null}
                                       </Form.Item>
                                    ))}
                                 </Col>

                                 <Col span={24}>
                                    <Button
                                       disabled={isDisabledInput('deleteButton')}
                                       style={{
                                          float: 'right',
                                          marginBottom: '20px'
                                       }}
                                       danger
                                       type="primary"
                                       icon={<MinusCircleOutlined />}
                                       onClick={() => remove(name)}>
                                       Удалить адрес
                                    </Button>
                                 </Col>
                              </Row>
                           ))}

                           <Row gutter={24}>
                              <Col span={24}>
                                 <Form.Item>
                                    <Button
                                       disabled={isDisabledInput('addButton')}
                                       type="dashed"
                                       onClick={() => add()}
                                       block
                                       icon={<PlusOutlined />}>
                                       Добавить
                                    </Button>
                                 </Form.Item>
                              </Col>
                           </Row>
                        </>
                     )}
                  </Form.List>
               </Form.Item>
            </Col>
         </Row>

         <Row gutter={[16, 24]}>
            {isSellerBlocked && (
               <Col
                  className="gutter-row"
                  span={24}
                  className="stats-card-count-col">
                  <Alert
                     message="В статусе отключен нельзя редактировать все поля"
                     type="warning"
                     showIcon
                  />
               </Col>
            )}

            {errorMessage && (
               <Col
                  className="gutter-row"
                  span={24}
                  className="stats-card-count-col">
                  <Alert
                     message={errorMessage.replace('Error: ', '')}
                     type="error"
                     showIcon
                  />
               </Col>
            )}

            <Col className="gutter-row" span={24} className="stats-card-count-col">
               <Form.Item>
                  <Button
                     type="primary"
                     htmlType="submit"
                     loading={isLoading}
                     disabled={isDisabledButton}>
                     {isEditForm ? 'Сохранить' : 'Создать'}
                  </Button>
               </Form.Item>
            </Col>
         </Row>
      </Form>
   );
};

export default SellerForm;
