import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';

dayjs.extend(isYesterday);
dayjs.extend(isTomorrow);
dayjs.extend(isToday);

function timestampToNormalDate(ts) {
   return dayjs(ts).format('DD.MM.YYYY HH:mm');
}

function timestampToNormalTime(ts) {
   return dayjs(ts).format('HH:mm');
}

function timestampToNormalDDMMYY(ts) {
   return dayjs(ts).format('DD.MM.YYYY');
}

function timestampToNormalDDMMYYWithDay(ts) {
   const value = dayjs(ts);

   if (value.isToday()) {
      return 'Сегодня';
   }

   if (value.isTomorrow()) {
      return 'Завтра';
   }

   if (value.isYesterday()) {
      return 'Вчера';
   }

   return value.format('DD.MM.YYYY');
}

function timestampToNormalDDMMYY_HHMM(ts) {
   if (!ts) return 'Неизвестно';

   return dayjs(ts).format('YYYY.MM.DD | HH:mm');
}

export {
   timestampToNormalTime,
   timestampToNormalDate,
   timestampToNormalDDMMYY,
   timestampToNormalDDMMYYWithDay,
   timestampToNormalDDMMYY_HHMM
};
