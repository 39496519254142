import React, { useEffect, useState } from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import {
   Divider,
   Descriptions,
   Tag,
   Row,
   Col,
   Button,
   Space,
   Alert,
   Tabs,
   Card,
   List,
   message,
   Tooltip
} from 'antd';
import { getCenter } from 'geolib';
import CanDo from '@shared/lib/CanDo';
import { Empty } from 'antd';
import {
   ReloadOutlined,
   EditOutlined,
   EnvironmentOutlined
} from '@ant-design/icons';
import ListOfPushNotifications from '@features/list-pushes/ui/ListOfPushNotifications';
import ModalSellerForm from '@features/form-seller/ui/ModalSellerForm';
import { statusesOfSellers } from '@shared/const/statuses';
import { ArrowLeftOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Title, StatusTag, DateWithAgo } from '@shared/ui';
import stringToColor from '@shared/utils/stringToColor';
import OnlyViewPicturesWall from '@widgets/PicturesWall/OnlyViewPicturesWall';
import dayjs from 'dayjs';
import { deleteLeadById } from '@shared/api/all/lead';
import { UpdateSeller } from '@features/form-seller/model/services/UpdateSeller';
import DeleteEntityButton from '@widgets/Button/DeleteEntityButton';
import { GetSeller } from '@features/form-seller/model/services/GetSeller';
import { UpdateStatusOfLead } from '@features/list-lead/model/UpdateStatusOfLead';
import RatingListOfEntity from '@widgets/RatingListOfEntity';
import { useNavigate } from 'react-router-dom';
import NewEntityTag from '@shared/ui/Custom/NewEntityTag';
import ManageManagerOfSeller from './ManageManagerOfSeller';
import CanDoManager from '@shared/lib/CanDoManager';
import UserCardById from '@features/list-user/ui/UserCardById';
import { LeadCount } from './SellerComponents';
import { Spin } from '@shared/ui';
import SellerLeads from './SellerLeads';
import SellerSimpleDescriptions from './SellerSimpleDescriptions';
import {
   timestampToNormalTime,
   timestampToNormalDDMMYY
} from '@shared/utils/tsToTime';

const SimpleSellerDescription = ({ id }) => {
   const [data, setData] = useState({});
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate();
   const mapRef = React.createRef();
   const [modalShow, setModalShow] = useState(null);
   const [centerOfMap, setCenterOfMap] = useState({ center: [0, 0], zoom: 10 });

   const sellerDescriptionLabelsAndValues = [
      {
         label: 'ФИО',
         key: 'firstName',
         render: (_, record) =>
            `${record.lastName} ${record.firstName} ${record?.surName || ''}`
      },
      {
         label: 'Телефон',
         key: 'mobileNumber',
         render: (_) => _
      },
      {
         label: 'Почта',
         key: 'email',
         render: (_) => _ || 'Не задано'
      },
      {
         label: 'Компания',
         key: 'organization',
         render: (_) => (_ ? `${_}` : `Физ. лицо`)
      },
      {
         label: 'Дата регистрации',
         key: 'createdAt',
         render: (_) => `${timestampToNormalDDMMYY(_)} в ${timestampToNormalTime(_)}`
      },
      {
         label: 'Последний вход',
         key: 'lastSeenAt',
         render: (_) => <DateWithAgo date={_} showDate={false} />
      }
   ];

   const fetchData = () => {
      setLoading(true);
      GetSeller(id).then((res) => {
         setData({ ...res });
         setLoading(false);

         if (centerOfMap.center[0] !== 0) {
            return;
         }
         const centerCoords = getCenter(
            res?.addresses.map((e) => ({
               latitude: e.geoLat,
               longitude: e.geoLon
            }))
         );

         setCenterOfMap((prev) => ({
            ...prev,
            center: [centerCoords.latitude, centerCoords.longitude]
         }));
      });
   };

   useEffect(() => {
      fetchData();
   }, [id]);

   const closeModal = () => {
      fetchData();
      setModalShow(null);
   };

   const update = () => {
      setLoading(true);
      setTimeout(() => {
         fetchData();
      }, 500);
   };

   if (loading && !data?.id) {
      return (
         <Space
            style={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               height: '300px',
               width: '100%'
            }}>
            <Spin />
         </Space>
      );
   }

   const toCenterOfMap = (item) => {
      setCenterOfMap((prev) => ({
         ...prev,
         center: [item.geoLat, item.geoLon]
      }));

      mapRef.current.scrollIntoView({
         behavior: 'smooth',
         block: 'start'
      });
   };

   return (
      <>
         <Row gutter={24}>
            <Col span={24}>
               <Row gutter={24}>
                  <Col span={24}>
                     <ListOfPushNotifications
                        entityId={id}
                        entityType={'seller'}
                        limit={0}
                     />
                  </Col>
                  <Col span={24}>
                     <Divider orientation="left">Информация</Divider>
                     <Descriptions size="small">
                        {sellerDescriptionLabelsAndValues.map((e) => (
                           <Descriptions.Item
                              key={`${data.id}-${e.label}`}
                              label={e.label}
                              span={3}>
                              {e.render(data[e.key], data)}
                           </Descriptions.Item>
                        ))}
                        <Descriptions.Item
                           key={`meow-seller ${id}`}
                           label={`Количество заявок`}
                           span={3}>
                           <LeadCount sellerId={id} />
                        </Descriptions.Item>
                     </Descriptions>
                  </Col>
                  <Col span={24}>
                     <Divider orientation="left">Комментарий</Divider>
                     {data?.notes || 'Нет комментариев'}
                  </Col>

                  <Col span={24}>
                     <Divider orientation="left">
                        Адреса продавца{' '}
                        {data?.addresses?.length > 0 && (
                           <Tag bordered={false}>{data?.addresses?.length}</Tag>
                        )}
                     </Divider>

                     {data?.addresses?.length > 0 ? (
                        <>
                           <List
                              itemLayout="horizontal"
                              dataSource={data?.addresses || []}
                              renderItem={(item) => (
                                 <List.Item actions={[]}>
                                    <List.Item.Meta
                                       key={`${item.id}-${item.address}`}
                                       title={item.address}
                                       description={
                                          <Descriptions size="small">
                                             {/* <Descriptions.Item
                                                label="Количество заявок"
                                                span={3}>
                                                <Tag bordered={false}>
                                                   в разработке
                                                </Tag>
                                             </Descriptions.Item> */}

                                             <Descriptions.Item
                                                label="Район"
                                                span={3}>
                                                {item.districtName || 'Не найден'}
                                             </Descriptions.Item>

                                             <Descriptions.Item
                                                label="Место вывоза"
                                                span={3}>
                                                {item?.typeOfAddress || 'Не найден'}
                                             </Descriptions.Item>

                                             <Descriptions.Item
                                                label="Комментарий"
                                                span={3}>
                                                {item.comment || 'Не указан'}
                                             </Descriptions.Item>

                                             <Descriptions.Item
                                                label="Дата создания"
                                                span={3}>
                                                <DateWithAgo date={item.createdAt} />
                                             </Descriptions.Item>
                                          </Descriptions>
                                       }
                                    />
                                 </List.Item>
                              )}
                           />
                        </>
                     ) : (
                        <div className="description-comment-block">
                           <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>
                     )}
                  </Col>
               </Row>
            </Col>
         </Row>
      </>
   );
};

export default SimpleSellerDescription;
