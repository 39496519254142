import React from 'react';
import { StatusTag } from '@shared/ui';
import { Space } from 'antd';
import { unitSettings } from '@shared/const/units';
import { useNavigate } from 'react-router-dom';

const suffix = (unit) => unitSettings.find((e) => e?.value === unit)?.shortLabel;

const ListLeadsOfRoute = ({ leads }) => {
   const navigate = useNavigate();
   return (
      <Space direction="vertical">
         {leads.map((element) => (
            <div key={`list-leads-route-${element.id}`}>
               <span
                  className="green-span-url"
                  style={{ padding: '5px 0' }}
                  onClick={() => navigate(`/leads/${element.id}`)}>
                  {`Заявка №${element.id}`}
               </span>
               {' • '}
               <StatusTag status={element.status} type="lead" />
               {' • '}
               {element.address.districtName
                  ? `Район: ${element.address.districtName}`
                  : 'Не указан район'}

               {/* {element?.capacity
                  ? `${element.capacity} ${suffix(element.materialCategory.unit)}`
                  : `Не указан вес`} */}
            </div>
         ))}
      </Space>
   );
};

export default ListLeadsOfRoute;
