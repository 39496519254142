import React, { useState, useEffect } from 'react';
import RatingForm from './RatingForm';
import { CreateRating } from '../model/CreateRating';
import { UpdateRating } from '../model/UpdateRating';
import { GetRating } from '../model/GetRating';
import _ from 'lodash';

import { message } from 'antd';

const CreateOrEditRating = (props) => {
   const { id = null, callbackOnSuccess = () => {} } = props;
   const isEditForm = !!id;
   const [initialValues, setIntialValues] = useState({});
   const [loadForm, setLoadForm] = useState(false);

   useEffect(() => {
      if (!isEditForm) {
         return;
      }

      GetRating(id).then((res) => {
         setIntialValues({ ...initialValues, ...res });
         setLoadForm(true);
      });

      return () => {
         setLoadForm(false);
      };
   }, [id]);

   const onSuccess = async (values, setLoading) => {
      setLoading(true);

      if (isEditForm) {
         const ratingId = initialValues.id;
         const mergedObj = { ...initialValues, ...values };

         const updatedFields = _.pickBy(
            mergedObj,
            (v, k) => !_.isEqual(initialValues[k], v)
         );

         await UpdateRating(updatedFields, ratingId)
            .then(() => {
               callbackOnSuccess();
               message.success('Рейтинг изменен');
            })
            .catch((e) =>
               message.error(e?.message || 'Возникла ошибка при сохранении')
            )
            .finally(() => setLoading(false));

         return;
      }

      await CreateRating({ ...props, ...values })
         .then(() => {
            message.success('Рейтинг создан');
            callbackOnSuccess();
         })
         .catch(() => message.error('Рейтинг не создан, возникла ошибка'))
         .finally(() => setLoading(false));
   };

   if (!loadForm && isEditForm) {
      // Чтобы initialValues прогрузились при первом рендере, если это Редактируемая форма
      return <></>;
   }

   return (
      <RatingForm
         initialValues={initialValues}
         isEditForm={isEditForm}
         onSuccess={onSuccess}
         {...props}
      />
   );
};

export default CreateOrEditRating;
