import React, { useState, useEffect } from 'react';
import {
   Space,
   Button,
   Tag,
   Divider,
   Steps,
   List,
   Spin,
   Tooltip,
   Modal
} from 'antd';
import {
   DownloadOutlined,
   DeleteOutlined,
   EditOutlined,
   ExclamationCircleFilled
} from '@ant-design/icons';
import ModalButtonNoteCreate from './ModalButtonNoteCreate';
import { GetNoteList } from './../model/GetNoteList';
import { UpdateNote } from './../model/UpdateNote';
import { DateWithAgo } from '@shared/ui';
import ModalNoteForm from './ModalNoteForm';
const { confirm } = Modal;
import {
   timestampToNormalTime,
   timestampToNormalDDMMYY
} from '@shared/utils/tsToTime';
import { message } from 'antd';

const LIMIT = 4;

const Notes = ({ entityId, entityType, hideTitle = false }) => {
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [count, setCount] = useState(1);
   const [modalShow, setModalShow] = useState(null);

   const closeModal = () => {
      fetchData(entityId, currentPage);

      setModalShow(null);
   };

   const fetchData = (entityId, currentPage) => {
      setLoading(true);

      if (!entityId || !entityType) {
         return;
      }

      GetNoteList({
         entityId,
         entityType,
         page: currentPage,
         limit: LIMIT,
         sort: 'noteTs',
         order: 'desc',
         status: 'active'
      })
         .then((res) => {
            if (currentPage === 1) {
               setData([...res.data]);
            } else {
               setData((e) => [...e, ...res.data]);
            }

            setLoading(false);
            setCurrentPage(currentPage);
            setCount(res.count);
         })
         .catch((err) => {
            console.error(err);
            setLoading(false);
         });
   };

   useEffect(() => {
      if (entityId && entityType) {
         fetchData(entityId, currentPage);
      }
   }, [entityId, entityType, currentPage]);

   const showConfirm = (noteId) => {
      return confirm({
         title: 'Подтвердите удаление',
         icon: <ExclamationCircleFilled />,
         maskClosable: true,
         onOk() {
            UpdateNote(
               {
                  status: 'archive'
               },
               noteId
            )
               .then(() => {
                  fetchData(entityId, currentPage);
                  message.success('Удалено');
               })
               .catch((e) => {
                  message.error(e?.message || 'Возникла ошибка при сохранении');
               })
               .finally(() => setLoading(false));
         },
         okText: 'Удалить',
         cancelText: 'Отмена'
      });
   };

   if (loading) {
      return (
         <Space
            style={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               height: '300px',
               width: '100%'
            }}>
            <Spin />
         </Space>
      );
   }

   const isHaveElements = currentPage * LIMIT >= count;

   return (
      <Space direction="vertical" style={{ width: '100%' }} className="note">
         {hideTitle ? null : (
            <Divider orientation="left">
               Заметки <Tag bordered={false}>{count}</Tag>
            </Divider>
         )}

         <div style={{ style: '100%', marginBottom: '10px' }}>
            <ModalButtonNoteCreate
               closeModal={closeModal}
               entityId={entityId}
               entityType={entityType}
               buttonText={'Добавить заметку'}
            />
         </div>

         <ModalNoteForm
            selectedData={modalShow}
            closeModal={closeModal}
            entityId={entityId}
            entityType={entityType}
         />

         <Steps
            progressDot
            current={1}
            direction="vertical"
            items={data.map((e) => ({
               status: 'finish',
               description: (
                  <List.Item>
                     <List.Item.Meta
                        title={
                           <>
                              <Tooltip
                                 placement="top"
                                 title={
                                    <>
                                       <div style={{ whiteSpace: 'nowrap' }}>
                                          Создание:{' '}
                                          {`${timestampToNormalDDMMYY(
                                             e.createdAt
                                          )} в ${timestampToNormalTime(
                                             e.createdAt
                                          )}`}
                                       </div>
                                       <div style={{ whiteSpace: 'nowrap' }}>
                                          Изменение:{' '}
                                          {`${timestampToNormalDDMMYY(
                                             e.updatedAt
                                          )} в ${timestampToNormalTime(
                                             e.updatedAt
                                          )}`}
                                       </div>
                                    </>
                                 }>
                                 {e.cause}
                              </Tooltip>
                           </>
                        }
                        description={<div className="note-message">{e.message}</div>}
                     />
                     <div className="note-date">
                        <Tooltip
                           placement="top"
                           title={
                              <div className="note-date-tooltip">
                                 <DateWithAgo
                                    date={e.noteTs}
                                    showAgo={true}
                                    showDate={false}
                                 />
                              </div>
                           }>
                           {`${timestampToNormalDDMMYY(
                              e.noteTs
                           )} в ${timestampToNormalTime(e.noteTs)}`}
                        </Tooltip>
                        <span style={{ gap: '10px', display: 'flex' }}>
                           <Tooltip placement="top" title="Редактировать">
                              <div onClick={() => setModalShow(e)}>
                                 <EditOutlined />
                              </div>
                           </Tooltip>

                           <Tooltip placement="top" title="Удалить">
                              <div onClick={() => showConfirm(e.id)}>
                                 <DeleteOutlined />
                              </div>
                           </Tooltip>
                        </span>
                     </div>
                  </List.Item>
               )
            }))}
         />
         <Space
            direction="horizontal"
            style={{
               width: '100%',
               justifyContent: 'center',
               marginTop: '15px'
            }}>
            <Button
               type="primary"
               disabled={isHaveElements}
               loading={loading}
               icon={<DownloadOutlined />}
               onClick={() => setCurrentPage((e) => e + 1)}>
               Загрузить больше
            </Button>
         </Space>
      </Space>
   );
};

export default Notes;
