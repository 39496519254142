import { Button, Modal, Space, Spin, Alert, Empty, Tag, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { getCenter } from 'geolib';
import _ from 'lodash';
import { EnvironmentOutlined } from '@ant-design/icons';
import { GetLeadList } from '../model/GetLeadList';
import { getTimeAgo } from '@shared/utils/timeAgo';
import { StatusTag } from '@shared/ui';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { unitSettings } from '@shared/const/units';
import SimpleLeadInfo from '@features/list-lead/ui/SimpleLeadInfo';
import TooltipCustom from '@shared/ui/Tooltips/TooltipCustom';
import TableLeadCategories from '@features/list-lead/ui/TableLeadCategories';
import RowLeadCategories from '@features/list-lead/ui/RowLeadCategories';

const { Text } = Typography;

const MAX_HEIGHT_PX = 600;

const suffix = (unit) => unitSettings.find((e) => e?.value === unit)?.shortLabel;

const MapOfCoordinatesOfLeads = () => {
   const [centerOfMap, setCenterOfMap] = useState({
      center: ['55.7539', '37.6208'],
      zoom: 10
   });
   const [listCoordinates, setListCoordinates] = useState([]);
   const [loading, setLoading] = useState(false);
   const [clickedLeadId, setClickedLeadId] = useState(null);
   const navigate = useNavigate();

   useEffect(() => {
      fetchData();
   }, []);

   const fetchData = () => {
      setLoading(true);

      GetLeadList({
         page: 1,
         limit: 1000,
         status: ['searching', 'active', 'inwork']
      })
         .then((res) => {
            const listOfData =
               res?.data?.map((e) => ({
                  ...e,
                  geoLat: e.address.geoLat,
                  geoLon: e.address.geoLon
               })) || [];
            setListCoordinates(listOfData);
            // loadCenter(listOfData);
            setLoading(false);
         })
         .catch(() => {
            setLoading(false);
         });
   };

   // const loadCenter = (ls) => {
   //    const dot = ls.map((e) => ({
   //       ...e,
   //       latitude: e.geoLat,
   //       longitude: e.geoLon
   //    }));

   //    if (isArrayEqual(dot, listCoordinates)) {
   //       return;
   //    }

   //    const centerCoords = getCenter(dot);
   //    setListCoordinates(dot);
   //    setCenterOfMap((prev) => ({
   //       ...prev,
   //       center: [centerCoords.latitude, centerCoords.longitude]
   //    }));
   // };

   const mapRef = React.createRef();

   if (loading) {
      return (
         <Space
            style={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               height: '100px',
               width: '100%'
            }}>
            <Spin />
         </Space>
      );
   }

   const setCLeadId = (id) => {
      setCenterOfMap((prev) => ({
         ...prev,
         zoom: 16,
         center: [
            listCoordinates.find((l) => l.id === id)?.geoLat,
            listCoordinates.find((l) => l.id === id)?.geoLon
         ]
      }));
      setClickedLeadId(id);
   };

   return (
      <>
         <div style={{ display: 'flex' }}>
            <YMaps>
               <div
                  ref={mapRef}
                  style={{
                     border: '1px #8080804a solid'
                  }}>
                  <Map
                     height={`${MAX_HEIGHT_PX}px`}
                     width={'800px'}
                     state={centerOfMap}
                     defaultState={{ zoom: 2 }}>
                     {listCoordinates.map((l) => (
                        <Placemark
                           onClick={() => setCLeadId(l?.id)}
                           key={`placemark-${l.id}`}
                           properties={{
                              iconContent: `<div class="placemark-title status-${l?.status}">Заявка №${l?.id}</div>`
                           }}
                           defaultGeometry={[l?.geoLat, l?.geoLon]}
                        />
                     ))}
                  </Map>
               </div>{' '}
            </YMaps>
            {listCoordinates.length > 0 ? (
               <div
                  className="list-of-drivers-onmap"
                  style={{ maxHeight: `${MAX_HEIGHT_PX}px`, marginLeft: '15px' }}>
                  <>
                     {' '}
                     {listCoordinates.map((l) => (
                        <TooltipCustom
                           key={l.id}
                           text={<SimpleLeadInfo data={l} />}
                           placement="leftTop">
                           <div
                              className={`list-of-drivers-onmap-info ${
                                 clickedLeadId === l?.id
                                    ? 'list-of-drivers-onmap-info--active'
                                    : ''
                              }`}
                              key={l?.id}
                              onClick={() => setCLeadId(l?.id)}
                              style={{ minWidth: '240px' }}>
                              <div className="list-of-drivers-onmap-info_id">
                                 <span
                                    className="green-span-url"
                                    style={{ margin: 0 }}
                                    onClick={() => navigate(`/leads/${l?.id}`)}>
                                    {`Заявка №${l?.id}`}
                                 </span>
                              </div>

                              <TooltipCustom
                                 text={
                                    <TableLeadCategories data={l.entityCategories} />
                                 }
                                 overlayClassName="tooltip-table-categories">
                                 <span>
                                    <RowLeadCategories data={l.entityCategories} />
                                 </span>
                              </TooltipCustom>

                              {/* <div className="list-of-drivers-onmap-info_name">
                                 Вес:{' '}
                                 {l.capacity
                                    ? `${l.capacity} ${suffix(
                                         l.materialCategory.unit
                                      )}`
                                    : `Не указан`}
                              </div> */}

                              {/* <div className="list-of-drivers-onmap-info_carnumber">
                                 Маршрут:{' '}
                                 <Space size="small" direction="vertical" wrap>
                                    {l?.routeId ? `№${l?.id}` : 'не назначен'}
                                 </Space>
                              </div> */}

                              {/* <div className="list-of-drivers-onmap-info_carnumber">
                                 Стоимость:{' '}
                                 <Space size="small" direction="vertical" wrap>
                                    {l.price
                                       ? `${l.price} ${
                                            l.priceType === 'unit' ? 'руб/кг' : 'руб'
                                         }`
                                       : 'Бесплатно'}
                                 </Space>
                              </div> */}

                              <div className="list-of-drivers-onmap-info_status">
                                 <StatusTag status={l?.status} type="lead" />{' '}
                              </div>
                           </div>
                        </TooltipCustom>
                     ))}
                  </>
               </div>
            ) : (
               <div style={{ height: '300px', width: '100%' }}>
                  <Empty
                     image={Empty.PRESENTED_IMAGE_SIMPLE}
                     description={
                        'На данный момент нет заявок активных или в работе'
                     }
                  />
               </div>
            )}
         </div>
      </>
   );
};

const ModalButtonLeadsMap = () => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   const showModal = () => {
      setIsModalOpen(true);
   };

   const handleOk = () => {
      setIsModalOpen(false);
   };

   const handleCancel = () => {
      setIsModalOpen(false);
   };

   return (
      <>
         <Button onClick={showModal} icon={<EnvironmentOutlined />}>
            Карта заявок
         </Button>
         <Modal
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            onClose={handleCancel}
            title={`Карта заявок`}
            footer={null}
            width={1200}
            destroyOnClose={true}>
            <MapOfCoordinatesOfLeads />
         </Modal>
      </>
   );
};

export default ModalButtonLeadsMap;
