import { Button, Drawer, Spin, message } from 'antd';
import { useState, useEffect } from 'react';
import CreateCopyLead from './CreateCopyLead';
import { Form, Input, Select } from '@shared/ui';
import { VerticalSpace } from '@shared/ui';
import { UsergroupAddOutlined, DeleteOutlined } from '@ant-design/icons';
import { getLeadConfirmById } from '@shared/api/all/lead';
import { updateLeadConfirmById } from '@shared/api/all/lead';
import { Row, Col, Radio } from 'antd';
const { TextArea } = Input;

const ModalButtonDeal = (props) => {
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState({});
   const { id, closeModal } = props;

   useEffect(() => {
      fetchData(id);
   }, [id]);

   const fetchData = (id) => {
      setLoading(true);
      getLeadConfirmById(id)
         .then((res) => {
            setData(res.json.data);
            setLoading(false);
         })
         .catch((err) => {
            console.error(err);
            setLoading(false);
         });
   };

   const reset = () => {
      const obj = {
         statusSeller: null,
         statusDriver: null
      };

      updateLeadConfirmById(id, obj).then((res) => {
         message.success('Сброс состояния сделки успешно выполнен');
         handleCancel();
      });
   };

   const onFinish = () => {
      const obj = {
         statusSeller: data?.statusSeller,
         statusDriver: data?.statusDriver
      };

      updateLeadConfirmById(id, obj).then((res) => {
         message.success('Статус сделки успешно изменен');
         handleCancel();
      });
   };

   const showModal = () => {
      setIsModalOpen(true);
   };

   const handleOk = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      closeModal();
   };

   if (loading) {
      return <Spin />;
   }

   if (!data?.leadId) {
      return <></>;
   }

   return (
      <>
         <Button onClick={showModal} icon={<UsergroupAddOutlined />}>
            Изменить состояние сделки
         </Button>

         <Button
            onClick={reset}
            icon={<DeleteOutlined />}
            style={{ margin: '10px 0' }}>
            Сбросить состояние
         </Button>
         <Drawer
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            onClose={handleCancel}
            title={`Подтверждение заявки №${id}`}
            footer={null}
            width={600}
            destroyOnClose={true}
            extra={<Button onClick={handleCancel}>Закрыть</Button>}>
            <Row gutter={24}>
               <Col span={24}>
                  <div style={{ marginBottom: '10px' }}>Продавец</div>

                  <Radio.Group
                     buttonStyle="solid"
                     defaultValue={data?.statusSeller}
                     optionType="button"
                     value={data?.statusSeller}
                     onChange={(e) =>
                        setData({ ...data, statusSeller: e.target.value })
                     }
                     block>
                     <Radio.Button value={'confirmed'}>Вывез</Radio.Button>
                     <Radio.Button value={'notconfirmed'}>Не вывез</Radio.Button>
                     <Radio.Button value={null}>Нет ответа</Radio.Button>
                  </Radio.Group>
               </Col>

               <Col span={24}>
                  {' '}
                  <VerticalSpace />
                  <div style={{ marginBottom: '10px' }}>Водитель</div>
                  <Radio.Group
                     buttonStyle="solid"
                     defaultValue={data?.statusDriver}
                     optionType="button"
                     value={data?.statusDriver}
                     onChange={(e) =>
                        setData({ ...data, statusDriver: e.target.value })
                     }
                     block>
                     <Radio.Button value={'confirmed'}>Вывез</Radio.Button>
                     <Radio.Button value={'notconfirmed'}>Не вывез</Radio.Button>
                     <Radio.Button value={null}>Нет ответа</Radio.Button>
                  </Radio.Group>
               </Col>
            </Row>
            <VerticalSpace />
            <Button
               type="primary"
               htmlType="submit"
               loading={loading}
               onClick={() => onFinish()}>
               Сохранить
            </Button>
         </Drawer>
      </>
   );
};

export default ModalButtonDeal;
