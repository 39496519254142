import { Space, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { FireFilled } from '@ant-design/icons';

const NewEntityTag = ({ date, isBig = false }) => {
   if (!date) {
      return null;
   }

   const halfDayInMs = 43200000;
   const isShow = dayjs() - dayjs(date) > halfDayInMs;

   if (isShow) {
      return null;
   }

   return (
      <Tooltip placement="bottomRight" title="Новый" arrow={false}>
         <FireFilled
            style={{ color: 'orange', fontSize: isBig ? '26px' : '16px' }}
         />
      </Tooltip>
   );
};

export default NewEntityTag;
