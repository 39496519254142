import React from 'react';
import TableLeads from '@features/list-lead/ui/TableLeads';
import { Title } from '@shared/ui';

const LeadsPage = () => {
   return (
      <div>
         <Title>Заявки</Title>
         <TableLeads />
      </div>
   );
};

export default LeadsPage;
